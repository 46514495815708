<template>
  <div class="gpt-running">
    <el-col :offset="8" :span="8">
      <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage"></el-progress>
      <p>{{ $t("gpt.runningInfo") }}</p>
      <el-button class="abort-btn" type="danger" icon="el-icon-switch-button" size="small"
        @click="abort">Abort</el-button>
    </el-col>
  </div>
</template>

<script>
import { abortTask } from "@/api/gpt";

export default {
  props: {
    current: {
      type: Object,
      default: () => {
        return {
          elapsedSeconds: 0,
          imageCount: 1,
        };
      },
    },
    multipler: {
      type: Number,
      default: 4,
    },
    abortCallback: {
      type: Function,
    },
  },
  methods: {
    abort() {
      abortTask(this.current.id).then((response) => {
        if (response.success && this.abortCallback) {
          this.abortCallback();
        }
      });
    },
  },
  computed: {
    percentage() {
      if (!this.current || !this.current.imageCount) {
        return 0
      }
      let v = parseInt((this.current.elapsedSeconds / this.current.imageCount / this.multipler) * 100)
      if (v < 0) {
        v = 0
      } else if (v > 99) {
        v = 99
      }
      return v
    }
  }
};
</script>

<style lang="scss">
.gpt-running {
  text-align: center;
  padding: 50px 0;

  p {
    font-size: 14px;
    font-weight: bold;
  }

  .abort-btn {
    margin-bottom: 20px;
  }
}
</style>